import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from "../components/Loader";

const InputCard = () => {

    const [loading, setLoading] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData(event.target);

        formData.append("access_key", "e0c83d94-36a4-4e69-b5a3-bdadab5c8a0e");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setLoading(false);
            toast.success("THANK YOU FOR CONTACTING US AND WE WILL BE IN TOUCH SHORTLY !");
            event.target.reset();
        } else {
            console.log("Error", data);
            toast.success(data.message);
        }
    };
    return (
        <>
            {loading && <Loader />}
            <div className="inp-card">
                <div className="inp-card-sub">
                    <h6 className="get-t">Get in Touch</h6>
                    <h3 className="c-form">Complete the Form Below</h3>
                    <form onSubmit={onSubmit}>
                        <div className="form-inp-main">
                            <label className='form-label'>Your Name</label>
                            <input type="text" name='name' className='form-inp' />
                        </div>
                        <div className="form-inp-main">
                            <label className='form-label'>Your Email</label>
                            <input type="email" name='email' className='form-inp' />
                        </div>
                        <div className="form-inp-main">
                            <label className='form-label'>Subject</label>
                            <input type="text" name='subject' className='form-inp' />
                        </div>
                        <div className="form-inp-main">
                            <label className='form-label'>Message</label>
                            <textarea name="message" rows="8" className='form-inp' id=""></textarea>
                        </div>
                        <div className="mt-5">
                            <button type="submit" className='sub-btn'> {loading ? "Sending..." : "Send Message"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default InputCard;