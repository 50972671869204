import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute, PublicRoute, AdminRoute, ProtectedRoute } from './routes/AuthRoute'; // Import all routes
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import Career from './pages/career/Career';
import Disclaimer from './pages/terms/Disclaimer';
import Terms from './pages/terms/Terms';
import Privacy from './pages/terms/Privacy';
import Start from './pages/terms/Start';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Verification from './pages/auth/Verification';
import VerifyCode from './pages/auth/VerifyCode';
import EmailVerified from './pages/auth/EmailVerified';
import ForgotPassword from './pages/auth/ForgotPassword';
import Dashboard from './pages/auth/Dashboard';
import AdminDash from './pages/admin/AdminDash';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/auth/ResetPassword';

const App = () => {

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Start />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/careers" element={<Career />} />
      <Route path="/home" element={<Home />} />
      <Route path="/terms&conditions" element={<Terms />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verification" element={<Verification />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/verify-e" element={<VerifyCode />} />

      {/* Email verification process using ProtectedRoute */}
      {/* Email verification process using ProtectedRoute */}
      <Route
        path="/verify-email"
        element={<ProtectedRoute element={<VerifyCode />} />}
      />
      <Route
        path="/verify"
        element={<ProtectedRoute element={<Verification />} />}
      />
      <Route
        path="/verified"
        element={<ProtectedRoute element={<EmailVerified />} />}
      />


      {/* Auth routes */}
      <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
      <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />

      {/* Private Routes (Requires Login) */}
      <Route path="/dashboard/user" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

      {/* Admin Routes (Requires Admin Access) */}
      <Route path="/dashboard/admin" element={<AdminRoute><AdminDash /></AdminRoute>} />

      {/* Catch-all route for 404 pages */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
