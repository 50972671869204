import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Dashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get('token');
        const authData = Cookies.get('auth');

        if (!token || !authData) {
            // Redirect to login if no token or auth data
            navigate('/login');
            return;
        }

        try {
            const parsedAuthData = JSON.parse(authData);
            const role = parsedAuthData?.user?.role;
            if (role === 0) {
                // Redirect to user dashboard if user is a regular user
                navigate('/dashboard/user');
            } else {
                // Redirect to login for invalid roles
                navigate('/login');
            }
        } catch (error) {
            // Handle JSON parsing errors or invalid auth data
            navigate('/login');
        }
    }, [navigate]);
    return (
        <div>
            <Navbar />
            <div className="mt-top"></div>
            <h1 className="d-flex justify-content-center align-items-center">User Dashboard</h1>
        </div>
    );
};

export default Dashboard;
