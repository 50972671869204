import React from 'react';
import LOGOD from "../../assets/images/logo-w.png";
import { Link } from 'react-router-dom';
const Start = () => {
    return (
        <>
            <div className="disclaimer-m">
                <div className='m-h-100 disclaimer-sub'>
                    <div className="container">
                        <div className="pt-5">
                            <img src={LOGOD} alt="" />
                        </div>
                        {/* <h1 className='common-title  c-clr my-3'>PROFESSIONAL CLIENT DISCLAIMER</h1> */}
                        <h3 className='text-coomon-color'>Welcome to the  Premier Quantitative   Strategies (PQS) website</h3>
                        <p className="common-text text-coomon-color">
                            <br />
                            By continuing to use the website you :
                            <br />
                            <ul>
                                <li className='my-3'> Agree to the website terms and conditions and privacy policy.</li>
                                <li className='my-3'>      Agree that you are a professional customer or eligible counterparty. <br /> This website is not intended for retail clients - if you are in doubt as to what type of customer you are, you should consult your financial adviser.</li>
                            </ul>

                            <br />

                        </p>
                        <div className="pb-4">
                            <Link to="/home" className='accept-btn-c accept-btn mx-3'> ACCEPT</Link>
                            <Link to="" className='accept-btn-c decline-btn mx-3'> DECLINE</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Start;